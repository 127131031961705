button.rec.rec-arrow {
  background-color: transparent;
  color: gray;
  font-size: 1.1rem;
  width: 1.7rem;
  height: 1.7rem;
  min-width: 1.7rem;
  line-height: 1.7rem;
}

button.rec.rec-arrow:hover,
button.rec.rec-arrow:focus {
  background-color: rgba(128, 128, 128, 0.2);
  color: #fe0000;
}

button.rec.rec-dot {
  background-color: rgba(128, 128, 128, 0.2);
  box-shadow: 0 0 1px 3px gray;
}

button.rec.rec-dot_active {
  background-color: #fe0000;
  box-shadow: 0 0 1px 3px gray;
}

.rec-item-wrapper {
  height: 100%;
}
